import { Component } from '@angular/core';
import { Subject } from 'rxjs';
//import { AngularEditorConfig } from '@kolkov/angular-editor';
//import { NgbModal,NgbModalRef,NgbCalendar, NgbDateStruct,NgbDate } from '@ng-bootstrap/ng-bootstrap';

import { AppService } from './services/app.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'auth-admin';
  showScroll: boolean;
  showScrollHeight = 300;
  hideScrollHeight = 10;

  userActivity: any;
  userInactive: Subject<any> = new Subject();

  /*htmlContent = '';
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  }*/
  constructor(private appService: AppService,
  ){
  }
  
   ngOnInit(): void {
    console.log('***************Hello******'+environment.apiBase);
  }
  getClasses() {
    const classes = {
      'pinned-sidebar': this.appService.getSidebarStat().isSidebarPinned,
      'toggeled-sidebar': this.appService.getSidebarStat().isSidebarToggeled
    }
    return classes;
  }
  toggleSidebar() {
    this.appService.toggleSidebar();
  }
  
}
