import { Injectable } from '@angular/core';
import { Cookie } from 'ng2-cookies';

const TOKEN_KEY = 'access_token';
const REFRESHTOKEN_KEY = 'refresh_token';
const USER_KEY = 'user_name';
const ROLE_KEY = 'user_role';
const TENANT_KEY = 'tenant_name';
const OWNER_KEY = 'owner_name'
const USER_DATA_KEY = 'user_data';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  constructor() { }

  signOut(): void {
    //window.sessionStorage.clear();
  }

  //var expireDate = new Date().getTime() + (1000 * token.expires_in);

  /*window.sessionStorage.removeItem(TOKEN_KEY);
  window.sessionStorage.setItem(TOKEN_KEY, token);
  window.sessionStorage.getItem(TOKEN_KEY);*/

  public checkAcessToken(): boolean{
    return Cookie.check(TOKEN_KEY);
  }
  public getToken(): string {
    return Cookie.get(TOKEN_KEY);
  }
  public getRefreshToken() {
    return Cookie.get(REFRESHTOKEN_KEY);
  }
  public getUser() {
    /*const user = Cookie.get(USER_DATA_KEY);
    if (user) {
      return JSON.parse(user);
    }*/
    return Cookie.get(USER_KEY);
  }
  public getUserRole(){
    return Cookie.get(ROLE_KEY);
  }
  public getTenantName() {
    return Cookie.get(TENANT_KEY);
  }
  public getOwnerName() {
    return Cookie.get(OWNER_KEY);
  }

  public saveAccessToken(token: string): void {
    //Cookie.delete(TOKEN_KEY);
    Cookie.set(TOKEN_KEY, token);
  }

  public saveRefreshToken(token: string): void {
    //Cookie.delete(REFRESHTOKEN_KEY);
    Cookie.set(REFRESHTOKEN_KEY, token);
  }

  public saveBothToken(tokenData: any): void {
    //Cookie.delete(TOKEN_KEY);
    //Cookie.delete(REFRESHTOKEN_KEY);
    Cookie.set(TOKEN_KEY, tokenData.access_token);
    Cookie.set(REFRESHTOKEN_KEY, tokenData.refresh_token);
  }

  public saveAllData(token: string,refreshToken: string,userName: string,role: string,tenant: string): void {
    /*Cookie.delete(TOKEN_KEY);
    Cookie.delete(REFRESHTOKEN_KEY);
    Cookie.delete(USER_KEY);
    Cookie.delete(ROLE_KEY);
    Cookie.delete(TENANT_KEY);*/

    Cookie.set(TOKEN_KEY, token);
    Cookie.set(REFRESHTOKEN_KEY, refreshToken);
    Cookie.set(USER_KEY, userName);
    Cookie.set(ROLE_KEY, role);
    Cookie.set(TENANT_KEY, refreshToken);
  }

  public saveUser(user: any): void {
    Cookie.set(USER_KEY, user.userName);
    Cookie.set(ROLE_KEY, user.userRole);
    Cookie.set(TENANT_KEY, user.clientName);
    Cookie.set(OWNER_KEY, user.ownerName);
  }
}
