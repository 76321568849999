import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-logout',
  template: '',
  styles: [''],
})
export class LogoutComponent implements OnInit {

  constructor(private router: Router, private authService: AuthService,
    ) {
  }

  ngOnInit() {
    console.log('%%inside- User logout--');
    this.authService.logout();
    this.router.navigate(['/home']);  
    //this.router.navigateByUrl('https://nexrpa.com/');

  }

}
