import { Component, OnInit } from '@angular/core';
import { Cookie } from 'ng2-cookies';
import { AppService } from './../../services/app.service';
import { UserService } from '../../shared/services/user.service';
import { User } from '../../models/user.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AdminDashService } from '../../pages/admin-dash/services/admin-dash.service'
import { AuthService } from '../../shared/services/auth.service';
import { TokenStorageService } from '../../shared/services/token-storage.service';
import { environment } from '../../../environments/environment'

@Component({
  selector: 'app-sidebar-admin',
  templateUrl: './sidebar-admin.component.html',
  styleUrls: ['./sidebar-admin.component.scss']
})
export class SidebarAdminComponent implements OnInit {

  userFullName: string;
  userRole: string;
  lastLoggedIn: string;
  dateTimeFormat: string; 

  userProfile: User;

  constructor(private appService: AppService,
              private userService: UserService,
              private adminDashService: AdminDashService,
              private authService: AuthService, private tokenStorageService: TokenStorageService) { }


  ngOnInit() {
    console.log('***ngOnInit: sidebar-admin');
    // Clear the cookie & local
    //this.deleteSpecificCookies();
    this.dateTimeFormat = environment.dtFormatDefault
    this.getUserData();    
  }

  getUserData(){
    console.log('>>>>>getUserData- sidebar-admin>>>>> ')
    this.adminDashService.getUserData().subscribe({
      next: (response: any) => {
        console.log(">>>getUserData from service:sidebar aadmin- "+JSON.stringify(response));
        if(response.status == environment.SUCCESS){
          this.userProfile =  response.data;
        }else{
          // Get from cookie beacuse it happens for first time
          const userData: User = {
            userName: this.tokenStorageService.getUser(),userRole: this.tokenStorageService.getUserRole(),
            clientName: this.tokenStorageService.getTenantName(), ownerName: this.tokenStorageService.getOwnerName()}
          
          this.userProfile = userData;
          //this.toastNotifyService.showErrorWithTimeout(response.message, "Notification", 7000);
        }           
      },
      error: (e) => {
        console.error(e);          
      }
    });
  }
  deleteSpecificCookies(){
    Cookie.delete("org_id");
    Cookie.delete("org_name");
    Cookie.delete("dtFormat");
    console.log('***specific cookies deleted***');
  }

}
