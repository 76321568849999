import { Component, OnInit, Input } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-popup',
  templateUrl: './popup-modal.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupModalComponent implements OnInit {
  @Input() tokenMsg: string;
  @Input() modalRef: NgbModalRef;

  constructor() { }

  ngOnInit() {
    console.log('***inside init--PopupModalComponent-'+this.tokenMsg);
  }

  confirm() {
    console.log('****confirm- PopupModalComponent');    
  }
  /** Dismiss dialog */
  cancel() {
    console.log('****cancel-dismiss PopupModalComponent');
    this.modalRef.dismiss();
  }

  /** Close modal */
  close() {
    console.log('****close modal*****');
    this.modalRef.close();
  }
}