import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders,HttpParams, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,HttpErrorResponse } from "@angular/common/http";
//import { UserService } from '../../services/user.service';
import { User } from '../../models/user.model';
import { JwtHelper } from '../../shared/util/jwt.helper';
import { map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable, } from 'rxjs/internal/Observable';
import { Cookie } from 'ng2-cookies';
import {TokenStorageService} from '../services/token-storage.service';
import { ConfigService } from '../../shared/services/config.service';
import { environment } from '../../../environments/environment';

import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root' // just before your class
})
export class AuthService {
  jwtHelper: JwtHelper = new JwtHelper();
  public $refreshToken = new Subject<boolean>();
  public $refreshTokenReceived = new Subject<boolean>();

  constructor(
    //private userService: UserService,
    private router: Router,private _http: HttpClient,
    private tokenService: TokenStorageService,private configService: ConfigService,
  ) {
      this.$refreshToken.subscribe((res:any)=> {
        this.refreshToken()
      }) 
  }
  loginAuth() {
    console.log('####### inside authserviceee- loginAuth.....'+this.configService.getConfiguration().clientId);
    const clientId = this.configService.getConfiguration().clientId; //environment.clientId
    //For opening in same window
    //window.location.href = 'http://localhost:8080/auth/oauth/authorize?response_type=code&&scope=user_info&client_id=' + this.clientId + '&redirect_uri='+ this.redirectUri;

    //For opening in diferent window
	  window.open(environment.authBase+environment.authorizeAPI+'?response_type=code&client_id='+clientId, "_blank"); //redirect_uri causes 403 forbidden error in hostinger vps 
  }

  /*refreshToken1() {
    const clientId = this.configService.getConfiguration().clientId; //environment.clientId
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 
      'AuthorizationBasic': 'Basic '+this.configService.getConfiguration().clientBasicEncrypt,
      })
    };
    let headers = new HttpHeaders({ 'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                                    'AuthorizationBasic': 'Basic '+this.configService.getConfiguration().clientBasicEncrypt,
                                  });
    return this._http.post(environment.authBase+environment.getTokenAPI+'?grant_type=refresh_token&refresh_token='+Cookie.get('refresh_token'), { headers: headers});
    //return this._http.post(environment.authBase+environment.getTokenAPI+'?grant_type=refresh_token&refresh_token='+Cookie.get('refresh_token')+'&client_id='+clientId, httpOptions);
  }*/

  refreshToken() {
    console.log('******auth service:refreshToken');
    let params = new URLSearchParams();
    params.append('grant_type', 'refresh_token');
    params.append('refresh_token',Cookie.get('refresh_token'));
    //params.append('client_id', environment.clientId); //this.clientId
    //params.append('client_secret', 'fDw7Mpkk5czHNuSRtmhGmAGL42CaxQB9'); // get in backend
    
    let headers = new HttpHeaders({ 'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                                    'Authorization-Client': 'Basic '+this.configService.getConfiguration().clientBasicEncrypt,
                                  });
    return this._http.post(environment.authBase+environment.getTokenAPI, params.toString(), { headers: headers })
      
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    localStorage.clear();    
    Cookie.deleteAll();// Nilan
    this.router.navigate(['/']);
  }

}
