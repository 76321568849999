<!--The content below is only a placeholder and can be replaced.-->
<div class="wrapper default-theme" ><!--appNoRightClick-->
  <app-navbar *ngIf="!showBlankPage"></app-navbar>
  <main>
    <!--<app-sidebar></app-sidebar>-->
    <div class="pages container-fluid pt-4 pb-4 pl-4 pr-4 ">
      <router-outlet></router-outlet>
      <!--<hr> added in footer page itself--> 
      <app-footer *ngIf="!showBlankPage"></app-footer>
    </div>
    <div class="overlay" (click)="toggleSidebar()"></div>
  </main>
 
</div>


