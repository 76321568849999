<div class="col-md-12 column" style="left: -3px">
  <ul class="pagination justify-content-end">
    <li class="p-2 text-orange">Total Items: <b>{{totalItem}}</b> &nbsp;&nbsp;</li>
    <li class="p-2 text-orange">Items Per Page:</li>
    <li >
      <select class="custom-select" style="width: auto" (change)="getSelectedCount()" [(ngModel)]="itemPerPage"><!-- [(ngModel)]="itemPerPage"-->
        <!--<option [ngValue]="2">2</option>-->
        <!-- [selected]="count == stepDetail.sequence" -->
        <option class='option' *ngFor='let count of itemCountList' [selected]="count == itemPerPage" [ngValue]="count">{{count}}</option>
      </select>
      &nbsp;&nbsp;
    </li>    
      <li class="page-item" *ngIf="currentPage?.number > 0; else prev">
          <a
                  class="page-link"
                  [routerLink]="['./']"
                  [queryParams]="{ page: currentPage?.number, size: currentPage?.size }"
          >Previous</a
          >
    </li>
      <ng-template #prev>
          <li class="page-item  disabled"><a class="page-link">Previous</a></li>
      </ng-template>

      <ng-container *ngFor="let item of counter(currentPage?.totalPages); let i = index">
          <li class="page-item" *ngIf="currentPage && currentPage.number != i; else active">
            <a class="page-link "
                    routerLink="./"
                    [queryParams]="{ page: i + 1, size: currentPage?.size }"
            >{{ i + 1 }}</a
            >
        </li>
          <ng-template #active>
              <li class="page-item active ">
                  <button class="page-link" disabled>{{ i + 1 }}</button>
              </li>
          </ng-template>
      </ng-container>

      <li class="page-item"
              *ngIf="currentPage?.number + 1 < currentPage?.totalPages; else next"
      >
        <a      class="page-link"
                [routerLink]="['./']"
                [queryParams]="{
                  page: currentPage?.number + 2,
                  size: currentPage?.size
                }"
          >Next</a
          >
    </li>
    <ng-template #next>
        <li class="page-item disabled "><a class="page-link">Next</a></li>
    </ng-template>
  </ul>
</div>
