<!--The content below is only a placeholder and can be replaced.-->
<div class="wrapper default-theme" [ngClass]="getClasses()"> <!--appNoRightClick-->
  <app-navbar-admin></app-navbar-admin>
  <main>
    <!--<app-sidebar></app-sidebar>-->
    <app-sidebar-admin></app-sidebar-admin>
    <div class="pages container-fluid pt-4 pb-4 pl-4 pr-4 ">
      <router-outlet></router-outlet>
      <hr>
      <!--<app-footer></app-footer>-->
    </div>
    <div class="overlay" (click)="toggleSidebar()"></div>
  </main>
</div>
