<aside class="sidebar shadow-sm">

  <div class="sidebar-profile d-flex flex-column justify-content-center pt-5 pb-3">
    <!-- Profile with photo starts-->
    <!--<div class="picture-wrapper mb-4">
      <div class="user-picture m-auto">
        <img src="./assets/img/user.jpg" class="img-fluid" alt="">
      </div>
    </div>
    
    <div class="profile-details text-center pb-4">
      <p class="mb-0 text-uppercase name">Jhon Doe</p>
      <small class="text-uppercase role">Administrator</small>
    </div>-->
    <!-- Profile with photo ends-->
    
  </div>
  <div class="sidebar-search pt-1 pb-1 pl-3 pr-3">
    <input type="search" class="form-control rounded-pill" name="search" placeholder="Search ...">
  </div>
  <nav class="sidebar-menu pt-3 pb-3">
    <div class="menu-header mt-3 mb-2">
      Components
    </div>
    <ul class="menu-items">

      <li *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
        <a routerLinkActive="active" [routerLink]="[menuItem.path]" class="nav-link">
            <i class="ni {{menuItem.icon}}"></i>
            {{menuItem.title}}
        </a>
        
    </li>
      
      <!--
      <li >
        <a href="#" routerLink="/dashboard" routerLinkActive="active">
          <i class="fa fa-tv"></i>
          <span>Dashboard</span>
          <span class="badge badge-warning rounded-0 text-dark p-1">NEW</span>
        </a>
      </li>
      <li>
        <a href="#" routerLink="/forms" routerLinkActive="active">
          <i class="fas fa-gem"></i>
          <span>Forms</span>
        </a>
      </li>
      <li>
        <a href="#" routerLink="/tables" routerLinkActive="active">
          <i class="fa fa-table"></i>
          <span>Tables</span>
        </a>
      </li>
      <li>
        <a href="#" routerLink="/typography" routerLinkActive="active">
          <i class="fa fa-align-center"></i>
          <span>Typography</span>
        </a>
      </li>
      <li>
        <a href="#" routerLink="/maps" routerLinkActive="active">
          <i class="fa fa-globe"></i>
          <span>Maps</span>
        </a>
      </li>
      <li>
        <a href="#" routerLink="/notifications" routerLinkActive="active">
          <i class="fas fa-bell"></i>
          <span>Notifications</span>
        </a>

      </li> -->

     


    </ul>
    
    <!--<div class="menu-header mt-4 mb-4">
      Github Repo
    </div>
    <div class="view-source mb-3">
      <a href="https://github.com/azouaoui-med/lightning-admin-angular" target="_blank" class="btn btn-block btn-lightning rounded-0">View Source</a>
    </div>  --> 
  </nav>
</aside>