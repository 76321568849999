import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
//import {ToastComponent} from "../../shared/toast/toast.component";
import * as moment from 'moment';
//import * as _ from 'lodash';
import { Cookie } from 'ng2-cookies';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-admin-sample',
  templateUrl: './admin-sample.component.html',
  styleUrls: ['./admin-sample.component.scss']
})
export class AdminSampleComponent implements OnInit {

  title = 'demo1';
  userProfile: any;

  constructor(private router: Router, private _http: HttpClient,
              ) { }

  ngOnInit() {
    console.log('--inside  sample- ngOnInit----');
    //this.getUserProfile();
  }

}