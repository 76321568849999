import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastNotificationService {

  constructor(private toastrService: ToastrService) { }

  /*public showSuccess(test: string): void {
    this.toastrService.success('Message Success!', 'Title Success!');
  }

  public showInfo(): void {
    this.toastrService.info('Message Info!', 'Title Info!');
  }

  public showWarning(): void {
    this.toastrService.warning('Message Warning!', 'Title Warning!');
  }

  public showError(): void {
    this.toastrService.error('Message Error!', 'Title Error!');
  }*/


  public showSuccess(message:string , title: string): void{
  	this.toastrService.success(message, title)
  }
  public showSuccessWithTimeout(message:string , title: string, timespan: number): void{
  	this.toastrService.success(message, title,{
      timeOut : timespan // time unit here is milliseconds.
    })
  }
  showWarning(message:string , title: string){
  	this.toastrService.warning(message, title);
  }
  showWarningWithTimeout(message:string , title: string, timespan: number){
  	this.toastrService.warning(message, title ,{
      timeOut : timespan // time unit here is milliseconds.
    })
  }
  showError(message:string , title: string){
  	this.toastrService.error(message, title);
  }
  showErrorWithTimeout(message:string , title: string, timespan: number){
  	this.toastrService.error(message, title ,{
      timeOut : timespan // time unit here is milliseconds.
    })
  }
  showHTMLMessage(message:string , title: string): void{
    this.toastrService.success(message, title, {
      enableHtml :  true
    })
  }
  public showInfo(): void {
    this.toastrService.info('Message Info!', 'Title Info!');
  }


  /*showSuccessWithTimeout(message, title, timespan){
    this.toastr.success(message, title ,{
      timeOut : timespan // time unit here is milliseconds.
    })
  }

  showHTMLMessage(message, title){
    this.toastr.success(message, title, {
      enableHtml : true
    })
  }

  showError(message, title){
  	this.toastr.error(message, title);
  }
  showErrorWithTimeout(message, title, timespan){
  	this.toastr.error(message, title ,{
      timeOut : timespan // time unit here is milliseconds.
    })
  }

  showInfo(message, title){
  	this.toastr.info(message, title);
  }
  showInfoWithTimeout(message, title, timespan){
  	this.toastr.info(message, title ,{
      timeOut : timespan // time unit here is milliseconds.
    })
  }

  showWarning(message, title){
  	this.toastr.warning(message, title);
  }
  showWarningWithTimeout(message, title, timespan){
  	this.toastr.warning(message, title ,{
      timeOut : timespan // time unit here is milliseconds.
    })
  }*/
}