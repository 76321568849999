import { NgModule } from '@angular/core';
import { RouterModule, Routes} from '@angular/router';
import { HomeLayoutComponent } from './layout/home/home-layout.component';
import { AdminLayoutComponent } from './layout/admin/admin-layout.component';
import { AdminDashComponent } from './pages/admin-dash/admin-dashboard.component';
import { AdminSampleComponent } from './pages/admin-sample/admin-sample.component';
import { LogoutComponent } from './shared/logout/logout.component';

const routes: Routes = [
  //{ path: '', redirectTo: 'tutorials', pathMatch: 'full' },
  {
    path: '',
    component: HomeLayoutComponent,
    children: [
      { 
        path: '', 
        //component: LandingPageComponent, pathMatch: 'full'},
        loadChildren: () => import('src/app/layout/home/home-layout.module').then(m => m.HomeLayoutModule)
      },
    ]
  },  
  { 
    path: 'admin', 
    component: AdminLayoutComponent, 
    children: [
      {path: '',  redirectTo: 'dashboard', pathMatch: 'full'},
      { path: 'dashboard',  component: AdminDashComponent },
      { path: 'sample',     component: AdminSampleComponent },
      { path: 'authority',  loadChildren: () => import('./pages/authority/authority.module').then(m => m.AuthorityModule)},
      { path: 'authclient',  loadChildren: () => import('./pages/authclient/authclient.module').then(m => m.AuthClientModule)},
      { path: 'usercred',  loadChildren: () => import('./pages/usercred/usercred.module').then(m => m.UserCredModule)},
      { path: 'logout',       component: LogoutComponent }, 
    ]
  },
  /*{ 
    path: 'admin', 
    component: AdminLayoutComponent, 
    //canActivate: [AuthGuardAdmin],
    loadChildren: () => import('src/app/layout/admin/admin-layout.module').then(m => m.AdminLayoutModule)
  },*/
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }