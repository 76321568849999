<div class="modal-header">
    <!--<h4 class="modal-title" id="modal-basic-title">Token Usage</h4>-->
    <span>{{tokenMsg}}</span>
    <button type="button" class="close" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
    </button>  
</div>
<!--<div class="modal-body">
  <div>
    Are you sure you want to delete tenant
  </div>    
</div>

<div class="modal-footer">
    <button type="button" class="btn-custom p-1" (click)="confirm()">Confirm</button>
    <button type="button" class="btn-custom p-1" (click)="cancel()">Cancel</button>
</div> -->
