import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CollapseModule } from 'ngx-bootstrap/collapse';
//import {ToastComponent} from './toast/toast.component';
import {LoadingComponent} from './loading/loading.component';
import {PopupModalComponent} from './popup/popup-modal.component';

import {CommonModule} from '@angular/common';
//import {TestComponent} from './test/test.component';
import {ProgressBarComponent} from './progress-bar/progress-bar.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
//import {AuthGuardAdmin} from './interceptor/auth-guard-admin.service';
import {JwtInterceptor} from './interceptor/jwt-interceptor.service';

import {NavbarAdminComponent} from '../components/navbar-admin/navbar-admin.component'
import {SidebarAdminComponent} from '../components/sidebar-admin/sidebar-admin.component'

import {LogoutComponent} from './logout/logout.component';
//import { NoRightClickDirective } from './directives/no-right-click.directive'; 
//import { GlobalConstants } from './util/global.constant';
import { ToastNotificationService } from './services/toast-notify.service';
import { SpinnerService } from './services/spinner.service';
//import { SwalAlertService } from './services/swal-alert.service';
import { ListFilterPipe } from './pipes/list-filter-pipe';
import { MinuteSecondsPipe } from './pipes/minute-second-pipe';
import {PaginationComponent} from '../components/pagination/pagination.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    //AngularEditorModule,
    RouterModule,
    CollapseModule.forRoot(),
  ],
  exports: [
    // Shared Modules
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // Shared Components
    HttpClientModule,
    //ToastComponent,
    LoadingComponent,
    PopupModalComponent,
    ProgressBarComponent,
    NavbarAdminComponent,
    SidebarAdminComponent,
    //NavbarUserComponent,
    //SidebarUserComponent,
    LogoutComponent,
    // WebSocketService,
    //NoRightClickDirective,
    //GlobalConstants,
    ListFilterPipe,
    MinuteSecondsPipe,
    PaginationComponent,
  ],
  declarations: [
    //ToastComponent,
    LoadingComponent,
    PopupModalComponent,
    ProgressBarComponent,
    //TestComponent,
    NavbarAdminComponent,
    SidebarAdminComponent,
    LogoutComponent,
    //NoRightClickDirective,
    ListFilterPipe,
    MinuteSecondsPipe,
    PaginationComponent,
   ],
  providers: [
    //ToastComponent,
    //AuthGuardAdmin,
    ToastNotificationService,
    SpinnerService,
    //SwalAlertService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    
  ],
})
export class SharedModule {
}
