import { Router } from '@angular/router';
import { HttpClient,HttpEvent,HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Subject} from "rxjs";
import {Configuration} from '../models/config-model';

@Injectable({
    providedIn: 'root' // just before your class
})
export class ConfigService {
    private config: Configuration;
    public configSubject: Subject<any> = new Subject<any>();

    constructor(private http: HttpClient,) {}

    public load(url:string) {// called from appmodule.ts => initConfig()
      console.log('Url in config  load>>>>>>>',url)
        return this.http.get(url) // This is configFile overridden: 'assets/config/config.json' in env
          .toPromise()
          .then((config: any) => {
            this.config = config;
            this.configSubject.next(this.config);
          })
          .catch((err: any) => {
            console.error('ERROR: ' + err);
          })
    }    
    getConfiguration() {
      return this.config;
    } 

    /*switch (envResponse.env) {
      case 'production': {
        request = this.http.get('./assets/env/env.' + envResponse.env + '.json');
      } break;

      case 'development': {
        request = this.http.get('./assets/env/env.' + envResponse.env + '.json');
      } break;

      case 'default': {
        console.error('Environment file is not set or invalid');
        resolve(true);
      } break;
    }*/
   
 }
 