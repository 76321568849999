import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Cookie } from 'ng2-cookies';
import * as moment from 'moment';
import { Location, LocationStrategy } from '@angular/common';

/*import {fadeAnimation} from '../../shared/animations/fadeIntRoute';
import * as _ from 'lodash';
import { ToastComponent } from "../../shared/toast/toast.component";*/
import {SpinnerService} from '../../shared/services/spinner.service';
import {ToastNotificationService} from '../../shared/services/toast-notify.service';
import { AuthService } from '../../shared/services/auth.service';
import { TokenStorageService } from '../../shared/services/token-storage.service';
import { AppService } from '../../services/app.service';
import { User } from '../../models/user.model';
import { environment } from '../../../environments/environment';
import { ConfigService } from '../../shared/services/config.service';

@Component({
  selector: 'app-home-layout',
  templateUrl: './home-layout.component.html',
  styleUrls: ['./home-layout.component.scss'],
  //animations: [fadeAnimation],
})
export class HomeLayoutComponent implements OnInit {

  showBlankPage: boolean = false;
  loggedIn: boolean = false;

  constructor(private router: Router,
    private appService: AppService,
    private _http: HttpClient,
    private auth: AuthService,
    private spinnerService: SpinnerService,
    private toastNotifyService: ToastNotificationService,
    private tokenStorageService: TokenStorageService,
    private configService: ConfigService,
    private location: Location, private locationStrategy: LocationStrategy
    ) { }

  getClasses() {
    const classes = {
      'pinned-sidebar': this.appService.getSidebarStat().isSidebarPinned,
      'toggeled-sidebar': this.appService.getSidebarStat().isSidebarToggeled
    }
    return classes;
  }
  toggleSidebar() {
    this.appService.toggleSidebar();
  }

  ngOnInit() {
    console.log('### inside HomeLayoutComponent-Ang15- ngOnInit ###');
    
    console.log("client-id from config: ",this.configService.getConfiguration().clientId,this.configService.getConfiguration().clientBasicEncrypt)
    console.log("Base url: ",document.baseURI);
    console.log("window-location: ",window.location);
    console.log("Host Name:: ",window.location.hostname); // window.location.protocol = http:
    console.log("Base url path: ",this.location.path());
    //let tenant = window.location.hostname; // get tenant i.e subdomain from "subdomain.domain.com"
    let tenant =  this.configService.getConfiguration().clientName;//environment.defaultTenant;

    this.loggedIn = false;
    //this.router.navigate(['/admin']);
    this.spinnerService.showSpinner();

    console.log("..current url.." + this.router.url);
    if (this.router.url == '/') {
      this.showBlankPage = true;
    } else {
      this.showBlankPage = false;
    }
    this.loggedIn = this.checkCredentials();
    console.log('is loged in..' + this.loggedIn); // false on load of home page having login
    if (this.loggedIn) {
      let user_role = this.tokenStorageService.getUserRole();
      console.log("user_role::::::>"+user_role);
      if (user_role == null || user_role == 'undefined' || user_role == '') {
        console.log('------------------getting user details----------')
        /*let headersJson = new HttpHeaders()
          .set('content-type', 'application/json')
          .set('Authorization', 'Bearer ' + Cookie.get('access_token')+'1234');*/
        //this._http.get(environment.apiBase+environment.userInfoAPI, { headers: headersJson })
        this._http.get(environment.authBase+environment.userInfoAPI+'/'+tenant) // header set in interceptor
          .subscribe({
            next: (data: any) => {
              //this.userProfile = res;
              console.log("user res:  " + JSON.stringify(data));
              //this.saveUser(res);
              this.tokenStorageService.saveUser(data);

              //console.log('>>>>get user>'+this.tokenStorageService.getUser)

              //forward to respective Dashboard...
              const role = data.userRole; //localStorage.getItem('role');
              console.log('Logged in user --> role ',role);
              if (role == 'Architect' || role =='Manager' || role == 'Developer') {
                this.router.navigate(['/user']); // enable auth guard
              } else if (role == 'ADMIN') {
                this.router.navigate(['/admin']);
              }else if (role == 'PARTNER') {
                console.log('*****forward to partner layout******');
                this.router.navigate(['/partner']);
              }else{
                console.log('!!!!! No role for auth service......')
              }
            },
            error: (e) => {
              console.error('!!! Error in fetching user auth details>>>> ',e);  
              let errorMessage = 'Unauthorized. Please check your credentials & user tenant.';
              this.toastNotifyService.showWarningWithTimeout(errorMessage,'ERROR',10000); 
              //TODO- LOGOUT       
            }
          });
      }    
    }
    let i = window.location.href.indexOf('code');
    console.log('value of i...' + i +" loogedin: "+this.loggedIn);
    if (!this.loggedIn && i != -1) {
      console.log("retrieving token...");
      this.retrieveToken(window.location.href.substring(i + 5));
    }

    setTimeout(() => {
      this.spinnerService.hideSpinner();
    }, 200);
  }

  checkCredentials() {
    //return Cookie.check('access_token');
    return this.tokenStorageService.checkAcessToken();
  }

  retrieveToken(code: string) {
    console.log('******auth code:'+code, this.configService.getConfiguration().clientBasicEncrypt);
    let params = new URLSearchParams();
    params.append('grant_type', 'authorization_code');
    //params.append('client_id', environment.clientId); //this.clientId
    //params.append('client_secret', 'fDw7Mpkk5czHNuSRtmhGmAGL42CaxQB9'); // get in backend
    params.append('redirect_uri', this.configService.getConfiguration().redirectUri);//environment.redirectURI
    params.append('code', code);
    //params.append('scope', 'read');

    let headers = new HttpHeaders({ 'Content-type': 'application/x-www-form-urlencoded; charset=utf-8',
                                    'Authorization': 'Basic '+this.configService.getConfiguration().clientBasicEncrypt,
                                  });
    this._http.post(environment.authBase+environment.getTokenAPI, params.toString(), { headers: headers })
      .subscribe(
        data => {
          console.log('>>>>>>>Retrieve Token Response: '+JSON.stringify(data))
          this.saveToken(data);
        },
        error => {
          console.log('***Error in saving tokenn...',error);
          alert('Error in getting and saving tokenn !!');
        }
      );
  }

  saveToken(token: any) {    
    //var expireDate = new Date().getTime() + (1000 * token.expires_in);
    this.tokenStorageService.saveBothToken(token);
    this.loggedIn = true;  // IMPORTANT
    window.location.href = this.configService.getConfiguration().redirectUri; //environment.redirectURI;//'https://localhost:4500';
  }
}