import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges ,OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent implements OnInit {

  @Input() currentPage: any;
  @Input()  public totalItem: number;
  @Input() itemPerPage: number;

  @Output() changeItemPerPage = new EventEmitter<any>(true);

  itemCountList: number[] = [5, 10, 20, 50, 100];

  constructor() {
  }

  ngOnInit() {
    console.log('****PaginationComponent****');
  }
  getSelectedCount(){
    console.log('****onselect****'+this.itemPerPage);
    this.changeItemPerPage.emit(this.itemPerPage);
    //this.itemCountList = environment.itemCountPerPageList;
  }

  counter(i = 1) {
    return new Array(i);
  }
}
