import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'listFilter'})
export class ListFilterPipe implements PipeTransform {

    /*For a single table data having a specific key name*/
    //Example- *ngFor="let apiKey of page?.content | listFilter : searchTerm"
    /*transform(list: any[], filterText: string): any {
        return list ? list.filter(item => item.apiKeyName.search(new RegExp(filterText, 'i')) > -1) : [];
    }*/

    /*For multiple table data for any key name*/
    //Example- *ngFor="let apiKey of page?.content | listFilter : 'apiKeyName' :searchTerm"
    
    transform(items: any[], field: string, value: string): any[] {
        if (!items) {
            return [];
        }
        if (!field || !value) {
            return items;
        }
        return items.filter(singleItem => singleItem[field].toLowerCase().includes(value.toLowerCase()));
    }

    // EXAMPLE "let product of products | search: { searchTerm, searchField }"
    /*transform(collection: any[], searchConfig: { searchTerm: string; searchField: string }): any[] {
        if (!collection) {
        return [];
        }
        if (!searchConfig.searchTerm || !searchConfig.searchField) {
        return collection;
        }
        return collection.filter(it => {
        return it[searchConfig.searchField]
            .toLowerCase()
            .includes(searchConfig.searchField.toLowerCase());
        });
    }*/
}

