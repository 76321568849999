import { Injectable } from '@angular/core';
import { HttpClient,HttpEvent,HttpRequest } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  isSidebarPinned = false;
  isSidebarToggeled = false;
  userPref: any;

  constructor(private http: HttpClient,) { }

  toggleSidebar() {
    this.isSidebarToggeled = ! this.isSidebarToggeled;
  }

  toggleSidebarPin() {
    this.isSidebarPinned = ! this.isSidebarPinned;
  }

  getSidebarStat() {
    return {
      isSidebarPinned: this.isSidebarPinned,
      isSidebarToggeled: this.isSidebarToggeled
    }
  }

  getSessionTimeoutValue(): any {
    return 240000; // Below some erro & logs out immediately- may be delete h2 in mem OR MS /MIN issue
    /*this.userPref = this.http.get<any>(environment.apiBase + '/user/getUserPreference');
    console.log('****userPreferenceForm from backend: '+JSON.stringify(this.userPref));
    return this.userPref.logoutTime;*/
  }

}
